import {
  ButtonTertiary,
  OptionsSelectionVehicleMolecule,
} from "c4u-web-components";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CheckInVehicleDocumentOrganism } from "..";
import {
  useRenave,
  useRenaveContext,
  useSessionContext,
  useVehicle,
} from "../../../hooks";
import {
  IFormikSetErrors,
  IVehicleDataFormMolecule,
  TypeSignatureEnum,
  VehicleEntryRequest,
} from "../../../models";
import {
  ContentCardMolecule,
  VehicleAptnessFormMolecule,
  VehicleDataFormMolecule,
} from "../../molecules";
import {
  ButtonWrapper,
  ManageVehicleContentWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  viewMode?: boolean;
}

export const CheckInVehicleOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const params = useParams<{ id: string }>();

  const { getVehicleByPlate } = useVehicle();
  const { postEntryVehicle } = useRenave();
  const { handleFormikException } = useSessionContext();
  const {
    kbbVehicleContext,
    restrictionContext,
    setVehicleEntryContext,
    setIsEditingVehicleEntryContext,
    vehicleEntryContext,
    vehicleCategoryContext,
    changeVehicleCategoryContext,
  } = useRenaveContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [goSubmit, setGoSubmit] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [enableSendDocument, setEnableSendDocument] = useState<boolean>();
  const [isAdvancedAtpv, setIsAdvancedAtpv] = useState<boolean>();
  const [isSubmittingDocument, setIsSubmittingDocument] = useState(false);
  const [openFirstModal, setOpenFirstModal] = useState(false);
  const [hasSelectedCategory, setHasSelectedCategory] =
    useState<boolean>(false);

  const handleSubmitVehicleEntryForm = useCallback(
    async (
      values: IVehicleDataFormMolecule,
      { setErrors }: IFormikSetErrors<IVehicleDataFormMolecule>
    ) => {
      try {
        let isEditValue = false;
        if (enableSendDocument !== undefined) isEditValue = !enableSendDocument;
        if (!!restrictionContext.vehicle.typeCrv) {
          await postEntryVehicle(
            new VehicleEntryRequest({
              ...values,
              id: Number(params.id),
              kbbId: kbbVehicleContext?.id.toString(),
              molicarID: kbbVehicleContext?.molicarID,

              plate: restrictionContext.vehicle.plate,
              numberCrv: restrictionContext.vehicle.numberCrv,
              renavam: restrictionContext.vehicle.renavam,
              typeCrv: restrictionContext.vehicle.typeCrv,

              brand: kbbVehicleContext?.brandName,
              modelYear: kbbVehicleContext?.year,
              model: kbbVehicleContext?.modelName,
              version: kbbVehicleContext?.versionName,
              isEdit: isEditValue,
            })
          );
        }

        if (parseInt(values.typeSignature!.toString()) === TypeSignatureEnum.AtpveAdvancedPdf) {
          setIsAdvancedAtpv(true);
        }
        setEnableSendDocument(true);
        setIsEditingVehicleEntryContext(isEditValue);
        if (!!restrictionContext.vehicle.typeCrv)
          setVehicleEntryContext({
            ...values,
            plate: restrictionContext.vehicle.plate,
            numberCrv: restrictionContext.vehicle.numberCrv,
            renavam: restrictionContext.vehicle.renavam,
            typeCrv: restrictionContext.vehicle.typeCrv,

            brand: kbbVehicleContext?.brandName,
            modelYear: kbbVehicleContext?.year,
            model: kbbVehicleContext?.modelName,
            version: kbbVehicleContext?.versionName,
            kbbId: kbbVehicleContext?.id.toString(),
            molicarID: kbbVehicleContext?.molicarID,
            firstAcess: true,
          });
      } catch (e: any) {
        handleFormikException(e, setErrors);
      } finally {
        setGoSubmit(false);
      }
    },
    [
      enableSendDocument,
      restrictionContext.vehicle.typeCrv,
      restrictionContext.vehicle.plate,
      restrictionContext.vehicle.numberCrv,
      restrictionContext.vehicle.renavam,
      postEntryVehicle,
      params.id,
      kbbVehicleContext?.id,
      kbbVehicleContext?.molicarID,
      kbbVehicleContext?.brandName,
      kbbVehicleContext?.year,
      kbbVehicleContext?.modelName,
      kbbVehicleContext?.versionName,
      setIsEditingVehicleEntryContext,
      handleFormikException,
      setVehicleEntryContext,
    ]
  );

  const searchVehicleCarDados = useCallback(
    (
      plate: string,
      setIsLoading: (v: boolean) => void,
      thenCallback: (data: any) => void,
      catchCallback: (v: any) => void
    ) => {
      setIsLoading(true);
      getVehicleByPlate(plate)
        .then((data) => {
          thenCallback(data);
          setOpenFirstModal(true);
        })
        .catch((err) => catchCallback(err))
        .finally(() => setIsLoading(false));
    },
    [getVehicleByPlate]
  );

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  useEffect(() => {
    if (vehicleEntryContext?.kbbId && vehicleEntryContext?.typeSignature && formValid) {
      setIsAdvancedAtpv(parseInt(vehicleEntryContext.typeSignature!.toString()) === TypeSignatureEnum.AtpveAdvancedPdf)
      setEnableSendDocument(true);
    }
  }, [formValid, vehicleEntryContext?.kbbId, vehicleEntryContext.typeSignature]);

  return (
    <>
      <ManageVehicleContentWrapper>
        <ContentCardMolecule title={t("AptnessQuery")}>
          <VehicleAptnessFormMolecule
            disableAll
            initialValues={restrictionContext.vehicle}
          />
        </ContentCardMolecule>

        <ContentCardMolecule title={t("Vehicle")}>
          <OptionsSelectionVehicleMolecule
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={changeVehicleCategoryContext}
            translate={t}
            hasSelectedCategory={hasSelectedCategory}
          />
          &nbsp;&nbsp;
          <VehicleDataFormMolecule
            initialValues={vehicleEntryContext}
            setIsSubmitting={setIsSubmitting}
            goSubmit={goSubmit}
            onSubmit={handleSubmitVehicleEntryForm}
            searchVehicleCarDados={searchVehicleCarDados}
            plate={restrictionContext.vehicle.plate}
            setFormValid={setFormValid}
            disableAll={!!enableSendDocument || !!props.viewMode}
            vehicleCategory={vehicleCategoryContext}
            setVehicleCategory={changeVehicleCategoryContext}
            openFirstModal={openFirstModal}
            setHasSelectedCategory={(value) => setHasSelectedCategory(value)}
          />
        </ContentCardMolecule>

        {!enableSendDocument && !props.viewMode ? (
          <ButtonWrapper className="no-top-margin">
            <ButtonTertiary
              sizex="md"
              loading={isSubmitting}
              onClick={() => setGoSubmit(true)}
              disabled={!formValid || !!props.viewMode}
            >
              {t("Advance")}
            </ButtonTertiary>
          </ButtonWrapper>
        ) : (
          <CheckInVehicleDocumentOrganism
            isAdvancedAtpv={isAdvancedAtpv}
            isSubmitting={isSubmitting}
            isSubmittingDocument={isSubmittingDocument}
            setIsSubmittingDocument={setIsSubmittingDocument}
            formValid={formValid}
            setEnableSendDocument={setEnableSendDocument}
            disableAll={!!props.viewMode}
          />
        )}
      </ManageVehicleContentWrapper>
    </>
  );
};
