import {
  Combo,
  FormikControlAtom,
  IKbbVehicle,
  MaskHelper,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
  SpinnerCenteredAtom,
  TypesHelper,
} from "c4u-web-components";
import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { StaticVehicleOptions, VehicleOptions } from "..";
import { EnumHelper, VehicleHelper } from "../../../helper";
import { useRenaveContext } from "../../../hooks";
import {
  IFindByPlateResponse,
  IVehicleDataFormMolecule,
  IVehicleEntryContext,
  TypeCrvEnum,
  TypeDocumentEnum,
  TypeEntryEnum,
  TypeSignatureEnum,
} from "../../../models";
import { FormikControlCalendarAtom } from "c4u-web-components";
import {
  SelectVehicleContentLoader,
  SelectVehicleDescription,
  SelectVehicleDiv,
  SelectVehicleName,
} from "../shared-style/molecules.shared-style";
import {
  MainWrapperVehicleDataForm,
  StyledFormRow,
} from "./vehicle-data-form.molecule.style";
import { VehicleDataFormValidation } from "./vehicle-data-form.molecule.validation";
import { IconCustomLabel } from "../../atoms";

export interface ISelectVehicleProps {
  id?: any;
  description?: any;
  error?: boolean;
}

interface IProps {
  plate: string;
  searchVehicleCarDados: (
    plate: string,
    setIsLoading: (v: boolean) => void,
    thenCallback: (data: IFindByPlateResponse) => void,
    catchCallback: (data: ISelectVehicleProps) => void
  ) => void;
  setIsSubmitting?: (v: boolean) => void;
  goSubmit?: boolean;
  onSubmit?: (values: any, errors: any) => Promise<void>;
  initialValues: IVehicleEntryContext;
  setFormValid?: (v: boolean) => void;
  disableAll: boolean;
  vehicleCategory: VehicleCategoriesEnum;
  setVehicleCategory: (vehicleCategory: VehicleCategoriesEnum) => void;
  openFirstModal?: boolean;
  setHasSelectedCategory: (value: boolean) => void;
}

export const VehicleDataFormMolecule: React.FC<IProps> = (props) => {
  const { t } = useTranslation();

  const { setFormValid: setFormValidOnParent } = props;

  const {
    kbbVehicleContext,
    setKbbVehicleContext,
    restrictionContext,
    vehicleEntryContext,
    sourcePartnerContext,
    setSourcePartnerContext,
  } = useRenaveContext();

  const [decoderFailedSearch, setDecoderFailedSearch] = useState<boolean>(false);
  const [hasVehicleSaved, setHasVehicleSaved] = useState<boolean>(false);
  const [vehicleSelect, setVehicleSelect] = useState<ISelectVehicleProps>();
  const [vehicleSearchFinished, setVehicleSearchFinished] = useState(false);

  const isSourceKbb = useMemo(
    () => sourcePartnerContext === SourcePartnerEnum.Kbb,
    [sourcePartnerContext]
  );

  const cleanVehicle = useCallback(() => {
    setVehicleSelect(undefined);
    setKbbVehicleContext(undefined);
    setVehicleSearchFinished(false);
  }, [setKbbVehicleContext]);

  function fillVehicleSelect(vehicle: IKbbVehicle) {
    setVehicleSelect({
      id: isSourceKbb ? vehicle.id : vehicle.molicarID,
      description: VehicleHelper.formatName(
        vehicle.brandName,
        vehicle.modelName,
        vehicle.year,
        vehicle.versionName
      ),
    });
    }

  useEffect(() => {
    if(kbbVehicleContext){
      fillVehicleSelect(kbbVehicleContext);
      setHasVehicleSaved(true);
    } else {
      handleSearchVehicleError()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSearchVehicleError = () => {
    cleanVehicle();
    setDecoderFailedSearch(true);
    setVehicleSearchFinished(true);
    setVehicleSelect({
      description: t("Error Service CarDados"),
      error: true,
    });
  };

  const formik = useFormik<IVehicleDataFormMolecule>({
    initialValues:
      props.initialValues ??
      ({
        emailCurrentOwner: "",
        valuePurchase: "",
      } as IVehicleDataFormMolecule),
    onSubmit: async (values, { setErrors }) => {
      if (props.onSubmit) await props.onSubmit(values, { setErrors });
    },
    validationSchema: VehicleDataFormValidation(),
    validateOnBlur: true,
    validateOnChange: false,
  });

  useEffect(() => {
    if (props.goSubmit) formik.submitForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.goSubmit]);

  useEffect(() => {
    if (props.setIsSubmitting) props.setIsSubmitting(formik.isSubmitting);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.isSubmitting]);

  useEffect(() => {
    if (
      formik.values &&
      !Object.keys(formik.errors).length &&
      (kbbVehicleContext?.id || kbbVehicleContext?.molicarID) &&
      restrictionContext &&
      setFormValidOnParent
    ) {
      setFormValidOnParent(true);
    }
  }, [
    formik.values,
    formik.errors,
    setFormValidOnParent,
    kbbVehicleContext?.id,
    kbbVehicleContext?.molicarID,
    restrictionContext,
  ]);

  useEffect(() => {
    if (props.initialValues?.dealershipEmail) {
      formik.setFieldValue(
        "dealershipEmail",
        props.initialValues.dealershipEmail
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.initialValues?.dealershipEmail]);

  const yearsListManufacture = useMemo(() => {
    const currentYear = new Date().getFullYear();

    if (!kbbVehicleContext) return [];

    if (kbbVehicleContext.year > currentYear) {
      return [
        new Combo({
          title: kbbVehicleContext.year - 1,
          value: kbbVehicleContext.year - 1,
        }),
      ];
    } else {
      return [
        new Combo({
          title: kbbVehicleContext.year - 1,
          value: kbbVehicleContext.year - 1,
        }),
        new Combo({
          title: kbbVehicleContext.year,
          value: kbbVehicleContext.year,
        }),
      ];
    }
  }, [kbbVehicleContext]);

  const signatureTypeOptions = useMemo<Combo[]>(() => {
    let value: TypeSignatureEnum | undefined;

    if (
      [TypeCrvEnum.Blue, TypeCrvEnum.Green].includes(
        restrictionContext.vehicle.typeCrv
      )
    ) {
      value = TypeSignatureEnum.AtpvOwnHandwritingCurrencyPaper;
    } else {
      value = TypeSignatureEnum.AtpveOwnHandwriting;
    }

    return [
      new Combo({ title: t(TypeSignatureEnum[value]), value }),
      new Combo({
        title: t(TypeSignatureEnum[TypeSignatureEnum.AtpveAdvancedPdf]),
        value: TypeSignatureEnum.AtpveAdvancedPdf,
      }),
    ];
  }, [restrictionContext.vehicle.typeCrv, t]);

  const sizeProps1inLine = {
    lg: 10,
    xl: 8,
  };
  const sizeProps2inLine = {
    md: 6,
    lg: 5,
    xl: 4,
  };

  return (
    <MainWrapperVehicleDataForm>
      <Form onSubmit={formik.handleSubmit}>
        <SelectVehicleDiv>
          {props.disableAll ? (
            kbbVehicleContext?.brandName ? (
              <SelectVehicleName>
                {VehicleHelper.formatName(
                  kbbVehicleContext.brandName,
                  kbbVehicleContext.modelName,
                  kbbVehicleContext.year,
                  kbbVehicleContext.versionName
                )}
              </SelectVehicleName>
            ) : (
              <SelectVehicleContentLoader>
                <SpinnerCenteredAtom />
                <SelectVehicleDescription>
                  {t("LoadingVehicleData")}
                </SelectVehicleDescription>
              </SelectVehicleContentLoader>
            )
          ) : vehicleSearchFinished ? (
            <>
              {vehicleSelect?.description ? (
                <SelectVehicleName isError={decoderFailedSearch}>
                  {vehicleSelect.description}
                </SelectVehicleName>
              ) : (
                <SelectVehicleDescription>
                  {t("Select vehicle")}
                </SelectVehicleDescription>
              )}
            </>
          ) : (
            kbbVehicleContext?.brandName && (
              <SelectVehicleName>
                {VehicleHelper.formatName(
                  kbbVehicleContext.brandName,
                  kbbVehicleContext.modelName,
                  kbbVehicleContext.year,
                  kbbVehicleContext.versionName
                )}
              </SelectVehicleName>
            )
          )}
        </SelectVehicleDiv>
        <StyledFormRow>
          <Col {...sizeProps1inLine}>
            {props.disableAll &&
              (vehicleEntryContext.kbbId || vehicleEntryContext?.molicarID) ? (
              <StaticVehicleOptions
                vehicleKbbId={vehicleEntryContext.kbbId.toString()}
                vehicleMolicarId={vehicleEntryContext.molicarID}
                sourcePartner={sourcePartnerContext}
                setSourcePartner={setSourcePartnerContext}
                setHasSelectedCategory={(value) =>
                  props.setHasSelectedCategory(value)
                }
              />
            ) : !hasVehicleSaved &&
              !(kbbVehicleContext?.id && kbbVehicleContext?.molicarID) ? (
              <VehicleOptions
                disableAll={props.disableAll}
                vehicleKbb={
                  kbbVehicleContext
                    ? {
                      idBrand: kbbVehicleContext.brandID,
                      id: kbbVehicleContext.id,
                      idModel: kbbVehicleContext.modelID,
                      year: kbbVehicleContext.year,
                      molicarID: kbbVehicleContext.molicarID,
                    }
                    : undefined
                }
                setHasSelectedCategory={(value) =>
                  props.setHasSelectedCategory(value)
                }
              />
            ) : (
              <StaticVehicleOptions
                vehicleKbbId={vehicleSelect?.id}
                sourcePartner={sourcePartnerContext}
                setSourcePartner={setSourcePartnerContext}
                setHasSelectedCategory={(value) =>
                  props.setHasSelectedCategory(value)
                }
              />
            )}
          </Col>
        </StyledFormRow>
        {kbbVehicleContext && (
          <>
            <Form.Row className="d-flex align-items-baseline mt-2">
              <FormikControlAtom
                type={"select"}
                data={yearsListManufacture}
                label={t("ManufacturingYear")}
                property={"manufactureYear"}
                formik={formik}
                translate={t}
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />

              <FormikControlAtom
                label={t("SecurityCodeCrv")}
                property={"securityCodeCrv"}
                formik={formik}
                translate={t}
                type="only-numbers"
                allowLeadingZeros
                maxLength={MaskHelper.CRV_SECURITY_CODE_LENGTH}
                {...sizeProps2inLine}
                placeholderChar={"\u2000"}
                disabled={props.disableAll}
              />
            </Form.Row>

            <Form.Row className="d-flex align-items-baseline">
              <FormikControlAtom
                type={"select"}
                data={EnumHelper.getComboFromEnum(TypeEntryEnum, t).filter(v => v.value !== TypeEntryEnum.ExternalEntry.toString())}
                label={t("EntryType")}
                property={"typeEntryEnum"}
                formik={formik}
                translate={t}
                {...sizeProps1inLine}
                disabled={props.disableAll}
              />
            </Form.Row>

            <Form.Row className="d-flex align-items-baseline">
              <FormikControlAtom
                type={"select"}
                data={EnumHelper.getComboFromEnum(TypeDocumentEnum, t)}
                label={t("TypeDocumentCurrentOwner")}
                property={"typeDocumentCurrentOwner"}
                formik={formik}
                translate={t}
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />
              {TypesHelper.toNumberNull(
                formik.values?.typeDocumentCurrentOwner
              ) === TypesHelper.toNumberNull(TypeDocumentEnum.Cpf) ? (
                <FormikControlAtom
                  label={t("DocumentCurrentOwner")}
                  property={"documentCurrentOwner"}
                  formik={formik}
                  translate={t}
                  mask={MaskHelper.Cpf}
                  {...sizeProps2inLine}
                  placeholderChar={"\u2000"}
                  disabled={props.disableAll}
                />
              ) : (
                <FormikControlAtom
                  label={t("DocumentCurrentOwner")}
                  property={"documentCurrentOwner"}
                  formik={formik}
                  translate={t}
                  mask={MaskHelper.Cnpj}
                  {...sizeProps2inLine}
                  placeholderChar={"\u2000"}
                  disabled={props.disableAll}
                />
              )}
            </Form.Row>
            <Form.Row>
              <FormikControlAtom
                label={t("EmailCurrentOwner")}
                property={"emailCurrentOwner"}
                formik={formik}
                translate={t}
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />
              <FormikControlAtom
                label={t("DealershipEmail")}
                property={"dealershipEmail"}
                formik={formik}
                translate={t}
                {...sizeProps2inLine}
                disabled
                placeholder={t("Loading") + "..."}
              />
            </Form.Row>

            <Form.Row className="d-flex align-items-baseline">
              <FormikControlCalendarAtom
                formik={formik}
                property="measurementOdometerDate"
                label={t("MileageMeasureDate")}
                translate={t}
                disabled={props.disableAll}
                xs={4}
              />

              <FormikControlAtom
                label={t("MileageMeasureHour")}
                property={"measurementOdometerHour"}
                translate={t}
                formik={formik}
                mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
                xs={4}
                disabled={props.disableAll}
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                type={"money"}
                formik={formik}
                label={t("ValuePurchase")}
                property="valuePurchase"
                translate={t}
                placeholder="R$"
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />

              <FormikControlCalendarAtom
                formik={formik}
                property="datePurchase"
                label={
                  <IconCustomLabel
                    label={t("DatePurchase")}
                    tooltipContent={t("PurchaseDateTooltipMessage")}
                  />
                }
                disabled={props.disableAll}
                xs={6}
                translate={t}
                {...sizeProps2inLine}
              />
            </Form.Row>

            <Form.Row className="d-flex align-items-baseline">
              <FormikControlAtom
                label={t("odometerMileage")}
                property={"odometerMileage"}
                formik={formik}
                translate={t}
                type="only-numbers"
                maxLength={8}
                thousandSeparator
                placeholderChar={"\u2000"}
                disabled={props.disableAll}
              />

              <FormikControlCalendarAtom
                label={t("EntryStockDate")}
                property="stockEntryDate"
                formik={formik}
                xs={4}
                disabled={props.disableAll}
                translate={t}
              />

              <FormikControlAtom
                label={t("EntryStockHour")}
                property={"stockEntryHour"}
                formik={formik}
                translate={t}
                mask={[/[0-2]/, /[0-9]/, ":", /[0-5]/, /[0-9]/]}
                {...sizeProps2inLine}
                disabled={props.disableAll}
              />
            </Form.Row>

            <Form.Row>
              <FormikControlAtom
                type={"select"}
                data={signatureTypeOptions}
                label={t("TypeSignature")}
                property={"typeSignature"}
                formik={formik}
                translate={t}
                disabled={props.disableAll}
              />
            </Form.Row>
          </>
        )}
      </Form>
    </MainWrapperVehicleDataForm>
  );
};
