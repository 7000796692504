import {
  IKbbVehicle,
  VehicleCategoriesEnum,
  SourcePartnerEnum,
} from "c4u-web-components";
import { useContext } from "react";
import { RenaveContext } from "../../contexts";
import {
  ICheckInZeroKmModel,
  ICheckOutZeroKmModel,
  IDiagnosisRestriction,
  IDocumentModel,
  IFindByPlateResponse,
  IGetRestrictionResponse,
  IUserAllInformation,
  IVehicleCheckoutModel,
  IVehicleDanfeForm,
  IVehicleEntryContext,
  PaymentStatusEnum,
  RenaveMenuStepsEnum,
  RenaveMenuStepsZeroKmEnum,
} from "../../models";

export interface RenaveProviderState {
  kbbVehicleContext: IKbbVehicle;
  setKbbVehicleContext: React.Dispatch<
    React.SetStateAction<IKbbVehicle | undefined>
  >;
  documentContext: IDocumentModel;
  setDocumentContext: React.Dispatch<IDocumentModel | undefined>;
  restrictionContext: IGetRestrictionResponse;
  setRestrictionContext: React.Dispatch<IGetRestrictionResponse | undefined>;
  diagnosisListContext: IDiagnosisRestriction[];
  setDiagnosisListContext: React.Dispatch<IDiagnosisRestriction[] | undefined>;
  vehicleEntryContext: IVehicleEntryContext;
  setVehicleEntryContext: React.Dispatch<IVehicleEntryContext | undefined>;
  menuCurrentStepContext: RenaveMenuStepsEnum;
  setMenuCurrentStepContext: React.Dispatch<RenaveMenuStepsEnum | undefined>;
  menuSelectedStepContext: RenaveMenuStepsEnum;
  setMenuSelectedStepContext: React.Dispatch<RenaveMenuStepsEnum | undefined>;
  vehicleDanfeContext: IVehicleDanfeForm;
  setVehicleDanfeContext: React.Dispatch<IVehicleDanfeForm | undefined>;
  vehicleOutDanfeContext: IVehicleDanfeForm;
  setVehicleOutDanfeContext: React.Dispatch<IVehicleDanfeForm | undefined>;
  isEditingVehicleEntryContext: boolean;
  setIsEditingVehicleEntryContext: React.Dispatch<boolean>;
  setUserContext: React.Dispatch<IUserAllInformation>;
  userContext: IUserAllInformation;
  vehicleCheckoutContext: IVehicleCheckoutModel;
  setVehicleCheckoutContext: React.Dispatch<IVehicleCheckoutModel | undefined>;
  zapayPaymentStatusContext: PaymentStatusEnum | undefined | null;
  setZapayPaymentStatusContext: React.Dispatch<
    PaymentStatusEnum | undefined | null
  >;
  renaveVehicleFetchedContext: boolean | undefined;
  setRenaveVehicleFetchedContext: React.Dispatch<boolean | undefined>;
  menuCurrentStepZeroKmContext: RenaveMenuStepsZeroKmEnum;
  setMenuCurrentStepZeroKmContext: React.Dispatch<RenaveMenuStepsZeroKmEnum>;
  menuSelectedStepZeroKmContext: RenaveMenuStepsZeroKmEnum | undefined;
  setMenuSelectedStepZeroKmContext: React.Dispatch<
    RenaveMenuStepsZeroKmEnum | undefined
  >;
  zeroKmVehicleEntryContext: ICheckInZeroKmModel | undefined;
  setZeroKmVehicleEntryContext: React.Dispatch<ICheckInZeroKmModel | undefined>;
  zeroKmVehicleCheckoutContext: ICheckOutZeroKmModel | undefined;
  setZeroKmVehicleCheckoutContext: React.Dispatch<
    ICheckOutZeroKmModel | undefined
  >;
  vehicleCategoryContext: VehicleCategoriesEnum;
  setVehicleCategoryContext: React.Dispatch<VehicleCategoriesEnum | undefined>;
  changeVehicleCategoryContext: (data: VehicleCategoriesEnum) => void;
  sourcePartnerContext: SourcePartnerEnum;
  setSourcePartnerContext: React.Dispatch<SourcePartnerEnum | undefined>;
  setContextFromFindByPlateResponse: (vehicle: IFindByPlateResponse) => IKbbVehicle;
}

export const useRenaveContext = () => {
  const context = useContext<RenaveProviderState>(RenaveContext);

  if (!context) {
    throw new Error("Empty context");
  }

  return context;
};
