import {
  ButtonSecondary,
  ButtonTertiary,
  ObjectHelper,
  ScrollHelper,
  TypesHelper,
  VehicleCategoriesEnum,
  VehicleDebtOriginEnum,
} from "c4u-web-components";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useParams } from "react-router-dom";
import { paths, skipUpdateKey, zapayCoverageStates } from "../../../constants";
import {
  useProducts,
  useQuery,
  useRenave,
  useRenaveContext,
  useSessionContext,
  useVehicle,
} from "../../../hooks";
import {
  ConflictRequestError,
  GetPlateRegisterRequest,
  GetRestrictionRequest,
  IDiagnosis,
  IFormikSetErrors,
  IGetRestrictionRequest,
  IGetRestrictionResponseVehicle,
  PaymentStatusEnum,
  PostOutsideVehicleRegisterRequest,
  PostVehicleRequest,
  RenaveMenuStepsEnum,
} from "../../../models";
import { OnScreenMessageAtom } from "../../atoms";
import {
  ContentCardMolecule,
  FeedbackModal,
  RestrictionListMolecule,
  VehicleAptnessFormMolecule,
} from "../../molecules";
import {
  ButtonWrapper,
  ManageVehicleContentWrapper,
} from "../shared-style/organisms.shared-style";

interface IProps {
  disabled?: boolean;
  hideModal?: boolean;
  updateRestrictions?: boolean;
}

export const VehicleAptnessOrganism: React.FC<IProps> = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const params = useParams<{ id: string }>();

  const { get: getQueryParam } = useQuery();
  const { getRestriction, postVehicle } = useRenave();
  const { postOutsideVehicleRegister, getDebtsByPlate } = useProducts();
  const { getVehicleByPlate } = useVehicle();

  const {
    setMenuCurrentStepContext,
    diagnosisListContext,
    setDiagnosisListContext,
    restrictionContext,
    setRestrictionContext,
    kbbVehicleContext,
    setKbbVehicleContext,
    setVehicleEntryContext,
    zapayPaymentStatusContext,
    setZapayPaymentStatusContext,
    changeVehicleCategoryContext,
    setContextFromFindByPlateResponse
  } = useRenaveContext();

  const { handleFormikException, showGenericErrorModal } = useSessionContext();

  const [goSubmit, setGoSubmit] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isPlateValid, setIsPlateValid] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [isSavingVehicle, setIsSavingVehicle] = useState(false);
  const [isCapable, setIsCapable] = useState<boolean>();
  const [goSave, setGoSave] = useState(false);

  const paymentInProcess = useMemo<boolean>(
    () => zapayPaymentStatusContext === PaymentStatusEnum.Paid,
    [zapayPaymentStatusContext]
  );

  const handleSaveAptnessQuery = useCallback(async () => {
    setIsSavingVehicle(true);
    try {
      const data = await postVehicle(
        new PostVehicleRequest({
          numberCrv: restrictionContext.vehicle.numberCrv,
          plate: restrictionContext.vehicle.plate,
          renavam: restrictionContext.vehicle.renavam,
          typeCrv: restrictionContext.vehicle.typeCrv,
          capable: isCapable,
          kbbId: kbbVehicleContext?.id ?? null,
          molicarId: kbbVehicleContext?.molicarID ?? null,
          brand: kbbVehicleContext?.brandName ?? null,
          model: kbbVehicleContext?.modelName ?? null,
          version: kbbVehicleContext?.versionName ?? null,
          modelYear: kbbVehicleContext?.year ?? null
        })
      );

      const { id, dealershipBusinessNumber: _, ...vehicleData } = data;

      setRestrictionContext({
        ...restrictionContext,
        vehicle: { ...restrictionContext.vehicle, ...vehicleData },
      });
      if (isCapable) {
        setMenuCurrentStepContext(RenaveMenuStepsEnum.CheckInVehicle);
        history.push(paths.checkInVehicle(id));
      } else {
        if (!props.hideModal)
          history.push(`${paths.checkInAptness(id)}?${skipUpdateKey}=true`);
      }
    } catch (e: any) {
      showGenericErrorModal(
        e?.message ? e.message : t("GenericErrorMsg"),
        null,
        t("Error")
      );
    } finally {
      setIsSavingVehicle(false);
    }
  }, [
    history,
    isCapable,
    postVehicle,
    props.hideModal,
    restrictionContext,
    setMenuCurrentStepContext,
    setRestrictionContext,
    showGenericErrorModal,
    kbbVehicleContext,
    t,
  ]);

  const handleDiagnosisVerification = useCallback(
    (diagnosis?: IDiagnosis) => {
      if (!diagnosis) return;

      const { capable, hasntEnoughBalance: _, ...diagnosisList } = diagnosis;
      setIsCapable(capable);

      if (capable) {
        if (!props.hideModal) setShowSuccessModal(true);
      } else {
        setDiagnosisListContext(
          Object.entries(diagnosisList)
            .filter(([_, value]) => typeof value === "boolean")
            .map(([key, value]) => ({ key, value }))
        );
        ScrollHelper.scrollToElementById(
          document,
          "vehicle-restriction-list",
          70
        );
        if (!props.hideModal) setShowWarningModal(true);
      }

      if (props.hideModal) setGoSave(true);
    },
    [props.hideModal, setDiagnosisListContext]
  );

  const handleVerifyZapeyStatus = useCallback(
    async (plate: string, debtsExist: boolean | undefined) => {
      if (debtsExist) {
        try {
          const data = await getDebtsByPlate(new GetPlateRegisterRequest({ plate }));
          setZapayPaymentStatusContext(data.status ?? null);
        } catch (error) {
          console.log("Error querying plate on zapey", error);
        }
      }
    },
    [getDebtsByPlate, setZapayPaymentStatusContext]
  );

  const handleRedirectZapey = useCallback(
    async (data: IGetRestrictionResponseVehicle) => {
      if (data.uf && zapayCoverageStates.includes(data.uf.toUpperCase())) {
        try {
          const urlRedirect = await postOutsideVehicleRegister(
            new PostOutsideVehicleRegisterRequest({
              detranUf: data.uf ?? "",
              plate: data.plate,
              renavam: data.renavam,
              origin: VehicleDebtOriginEnum.Renave,
              originId: Number(params.id),
            })
          );

          window.location.href = urlRedirect;
        } catch (error) {
          showGenericErrorModal(t("GenericErrorMsg"), null, t("Error"));
        }
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postOutsideVehicleRegister, showGenericErrorModal, t]
  );

  useEffect(() => {
    if (goSave) {
      handleSaveAptnessQuery();
      setGoSave(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [goSave]);

  async function getVehicleByPlateHandle(plate: string) {
    try {
      const vehicle = await getVehicleByPlate(plate);
      setContextFromFindByPlateResponse(vehicle);
    } catch (e: any) {
      if (e instanceof ConflictRequestError) {
        throw new Error(t("VehicleTypeNotSupported"));
      } else {
        setKbbVehicleContext(undefined);
        changeVehicleCategoryContext(VehicleCategoriesEnum.Car);
      }
    }
  }

  const handleSubmitAptnessForm = useCallback(
    async (
      values: IGetRestrictionRequest,
      { setErrors }: IFormikSetErrors<IGetRestrictionRequest>
    ) => {
      try {
        if (
          restrictionContext?.vehicle &&
          !ObjectHelper.compareObjectKeys(values, restrictionContext?.vehicle, [
            "plate",
            "renavam",
            "typeCrv",
            "numberCrv",
          ])
        ) {
          // clear context if searched vehicle is different
          setKbbVehicleContext(undefined);
          setVehicleEntryContext(undefined);
        }

        await getVehicleByPlateHandle(values.plate);
        const data = await getRestriction(new GetRestrictionRequest(values));
        setRestrictionContext({
          ...data,
          vehicle: {
            ...data.vehicle,
            ...values,
            plate: values.plate.replace("-", ""),
          },
        });

        handleVerifyZapeyStatus(
          data.vehicle.plate,
          data.debtInformation?.debtsExist
        );
        handleDiagnosisVerification(data.diagnosis);
      } catch (e) {
        handleFormikException(
          e,
          setErrors,
          t("GetRestrictionsGenericErrorMsg")
        );
      } finally {
        setGoSubmit(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      restrictionContext,
      getRestriction,
      handleVerifyZapeyStatus,
      handleFormikException,
      setRestrictionContext,
      handleDiagnosisVerification,
      setKbbVehicleContext,
      setVehicleEntryContext,
      t,
    ]
  );

  useEffect(() => {
    if (!isPlateValid && params.id === undefined) {
      setRestrictionContext(undefined);
      setDiagnosisListContext(undefined);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlateValid, params]);

  const handleGoSubmit = useCallback(() => {
    setRestrictionContext(undefined);
    setDiagnosisListContext(undefined);
    setZapayPaymentStatusContext(undefined);
    setGoSubmit(true);
  }, [
    setDiagnosisListContext,
    setRestrictionContext,
    setZapayPaymentStatusContext,
  ]);

  useEffect(() => {
    const skipUpdate = TypesHelper.toBooleanNull(getQueryParam(skipUpdateKey));
    if (props.updateRestrictions && !skipUpdate) handleGoSubmit();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateRestrictions]);

  useEffect(() => {
    if (goSubmit) setGoSubmit(false);
  }, [goSubmit]);

  return (
    <>
      <ManageVehicleContentWrapper>
        <ContentCardMolecule title={t("AptnessQuery")}>
          <VehicleAptnessFormMolecule
            initialValues={{
              plate: restrictionContext?.vehicle.plate ?? "",
              renavam: restrictionContext?.vehicle.renavam ?? "",
              numberCrv: restrictionContext?.vehicle.numberCrv ?? "",
              typeCrv: restrictionContext?.vehicle.typeCrv ?? "",
            }}
            setIsSubmitting={setIsSubmitting}
            goSubmit={goSubmit}
            setIsPlateValid={setIsPlateValid}
            onSubmit={handleSubmitAptnessForm}
            disableAll={!!props.disabled}
          />
        </ContentCardMolecule>

        {paymentInProcess && (
          <OnScreenMessageAtom
            text={t("PaymentInProcessWaitDetran")}
            type="warning"
            id="waiting-payment-confirmation"
          />
        )}

        {diagnosisListContext !== undefined && (
          <ContentCardMolecule
            title={t("VehicleWithRestrictions-NotApt")}
            id="vehicle-restriction-list"
          >
            <RestrictionListMolecule
              diagnosis={diagnosisListContext}
              disabilityReason={restrictionContext?.diagnosis?.disabilityReason}
              notEnoughBalance={
                restrictionContext?.diagnosis?.hasntEnoughBalance
              }
            />
          </ContentCardMolecule>
        )}
      </ManageVehicleContentWrapper>

      <ButtonWrapper>
        <ButtonSecondary
          disabled={isSubmitting || props.disabled}
          sizex="md"
          onClick={() => history.push(paths.myStock())}
        >
          {t("Cancel")}
        </ButtonSecondary>

        {restrictionContext?.debtInformation?.debtsExist ? (
          <ButtonTertiary
            sizex="md"
            onClick={() => handleRedirectZapey(restrictionContext.vehicle)}
            disabled={
              zapayPaymentStatusContext === undefined || paymentInProcess
            }
          >
            {t("PayDebts")}
          </ButtonTertiary>
        ) : (
          <ButtonTertiary
            sizex="md"
            loading={isSubmitting}
            onClick={handleGoSubmit}
            disabled={!isPlateValid || props.disabled}
          >
            {/* {props.updateRestrictions ? t("Update") : t("Consult")} */}
            {t("Consult")}
          </ButtonTertiary>
        )}
      </ButtonWrapper>

      <FeedbackModal
        title={t("ThisCarHasRestrictions")}
        typeIcon="warning"
        typeButton="confirm-yesno"
        size="md"
        show={showWarningModal}
        onHide={() => setShowWarningModal(false)}
        onClickButton={() => {
          handleSaveAptnessQuery();
          setShowWarningModal(false);
        }}
        isSubmitting={isSavingVehicle}
      />

      <FeedbackModal
        title={t("ThisCarHasNoRestrictions")}
        typeButton="confirm-yesno"
        size="md"
        show={showSuccessModal}
        onHide={() => setShowSuccessModal(false)}
        onClickButton={() => {
          handleSaveAptnessQuery();
          setShowSuccessModal(false);
        }}
        isSubmitting={isSavingVehicle}
      />
    </>
  );
};
